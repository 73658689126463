import { richText } from '../../utils/html';

export default async (
  { primary },
  pageData,
  { $prismic, $enhancedLinkSerializer }
) => {
  const moduldeId = primary.top_header_default.id;
  const defaultModule = moduldeId && (await $prismic.client.getByID(moduldeId));
  const defaultModuleData = defaultModule?.data;

  if (!defaultModuleData) return null;

  const defaultModuleProps = {
    text: richText(defaultModuleData.text),
    link: {
      link: $enhancedLinkSerializer(defaultModuleData.link),
      label: defaultModuleData.link_label,
    },
  };

  return defaultModuleProps;
};
